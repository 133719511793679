













































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import StatusIndicator from '@/components/StatusIndicator/StatusIndicator.vue';
import { Customer, prepareData } from '@/models/internal';
import { CustomerService, UserService } from '../../../services';
import { Logger } from '@/tools/Logger';
import { IModelView } from '@/lib/interfaces';
import { ApprovalStatus } from '@/lib/enum';
import { Utility } from '@/tools/Utility';
import { InfoList, InfoListSection as InfoListSectionType } from '@/lib/types';
import InfoListSection from '@/components/InfoList/InfoListSection.vue';
import CustomerAutoApprovalControls from '@/views/Dashboard/Customers/components/CustomerAutoApprovalControls.vue';

@Component({
  name: 'CustomersView',
  components: {
    ApprovalStatusIndicator,
    InfoListSection,
    StatusIndicator,
    CustomerAutoApprovalControls,
  },
})
export default class CustomersView extends Vue implements IModelView<Customer> {
  /**
   * ID of the Customer
   */
  @Prop({ required: true })
  private readonly id!: string | number;

  /**
     * Customer object and data to be rendered
     */
  public data: Customer | null = null;

  protected isAlertVisible = false;

  protected isDisplaySuccessAlert = true;

  protected alertMessages = {
    success: 'Site approval status has been updated successfully!',
    error: 'Sorry, there was an error while updating this Site.',
  };

  /**
   * Customer service
   */
  private readonly customerService: CustomerService = CustomerService.getInstance();

  /**
   * User service
   */
  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CustomersView' });

  /**
   * Loading state handlers
   */
  private loading = {
    overlay: false,
  };

  public created() {
    this.init();
  }

  /**
   * Fetch Customer data from the server
   */
  public async fetchData(): Promise<any> {
    const token = this.userService.getActiveToken();
    const customer = await this.customerService.api.findOne({
      id: this.id,
      authentication_token: token,
    });

    const preparedData = prepareData(customer, CustomerService.mapData);
    // TODO: Inserting nested associations here blew up :(
    // console.log('preparedData :>> ', preparedData);
    // const insertedData = await this.customerService.insert({ data: preparedData });
    // console.log('insertedData :>> ', insertedData);
    // return this.customerService.query().with('customer_products.product.assets').whereId(customer.id).first();
    return preparedData;
  }

  public async init(): Promise<void> {
    this.loading.overlay = true;
    try {
      const customer = await this.fetchData();
      if (customer) this.data = customer;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.overlay = false;
    }
  }

  /**
   * Name
   */
  protected get name() {
    if (!this.data) return '?';
    return Utility.titleCase(this.data.name);
  }

  /**
   * Phone
   */
  protected get phone() {
    if (!this.data) return '?';
    return Utility.formatPhone(this.data.phone);
  }

  /**
   * Address
   */
  protected get address() {
    if (!this.data) return '?';
    const address = this.data.address;
    const postalZip = this.data.postal_zip;
    return `${address}, ${postalZip}`;
  }

  /**
   * City
   */
  protected get city() {
    if (!this.data) return '?';
    return Utility.titleCase(this.data.city);
  }

  /**
   * Province
   */
  protected get province() {
    if (!this.data) return '?';
    return this.data.prov_state?.toLocaleUpperCase();
  }

  /**
   * Country
   */
  protected get country() {
    if (!this.data) return '?';
    return Utility.titleCase(this.data.country);
  }

  protected get blastrampId() {
    return (this.data) ? this.data.newid : '?';
  }

  /**
   * Status
   */
  // protected get status() {
  //   if (!this.data) return '?';
  //   return Utility.titleCase(this.data.approval_status || ApprovalStatus.Approved);
  // }

  /**
   * Contact Name
   */
  protected get contactName() {
    if (!this.data) return '?';
    return Utility.titleCase(this.data.contact);
  }

  /**
   * Contact Phone
   */
  protected get contactPhone() {
    if (!this.data) return '?';
    return Utility.formatPhone(this.data.contact_phone);
  }

  /**
   * Contact Email
   */
  protected get contactEmail() {
    if (!this.data) return '?';
    return Utility.titleCase(this.data.contact_email);
  }

  /**
   * Iterable list of Customer fields to be rendered
   */
  protected get customerInfo(): InfoList {
    return [
      {
        label: 'Name',
        value: this.name,
        icon: 'mdi-domain',
        divider: true,
      }, {
        label: 'Phone',
        value: this.phone,
        icon: 'mdi-phone',
        divider: true,
      }, {
        label: 'City',
        value: this.city,
        icon: 'mdi-city',
        divider: true,
      }, {
        label: 'Address',
        value: this.address,
        icon: 'mdi-map-marker',
        divider: true,
        siblings: [
          {
            label: 'Province',
            value: this.province,
          }, {
            label: 'Country',
            value: this.country,
          },
        ],
      }, {
        label: 'Blastramp ID',
        value: this.blastrampId,
        icon: 'mdi-application',
      },
    ];
  }

  /**
   * Iterable list of a Customer's contact fields to be rendered
   */
  protected get contactInfo(): InfoList {
    return [
      {
        label: 'Name',
        value: this.contactName,
        icon: 'mdi-account',
        divider: true,
      }, {
        label: 'Phone',
        value: this.contactPhone,
        icon: 'mdi-phone',
        divider: true,
      }, {
        label: 'Email',
        value: Utility.formatEmail(this.contactEmail),
        icon: 'mdi-email',
        divider: false,
      },
    ];
  }

  /**
   * All the columns in the Customer details card to be iterated
   * on by section
   */
  protected get sections(): InfoListSectionType[] {
    return [
      {
        label: 'Site Information',
        items: this.customerInfo,
      }, {
        label: 'Contact Information',
        items: this.contactInfo,
      },
    ];
  }

  /**
   * Give a status (approved/denied) for the Customer's `on_credit_hold` flag,
   * and default a null or unset value to approved.
   */
  protected get creditHold(): ApprovalStatus {
    if (!this.data) return ApprovalStatus.NotSet;
    if (this.data.on_credit_hold === null) return ApprovalStatus.Approved;
    return (this.data.on_credit_hold) ? ApprovalStatus.Denied : ApprovalStatus.Approved;
  }

  /**
   * User can update a Customer record
   */
  protected canUpdate() {
    return this.$ability.can('update', Customer);
  }

  /**
   * User can update a Customer record's status
   */
  protected canUpdateStatus() {
    return this.$ability.can('approve', Customer);
  }

  // protected async approve() {
  //   this.loading.overlay = true;
  //   const token = this.userService.getActiveToken();
  //   if (!token) throw Error('Unable to get token from active User');
  //   try {
  //     const response = await this.customerService.api.approve({
  //       id: this.id,
  //       authentication_token: token,
  //       customer: this.data,
  //     });

  //     // update customer data displayed
  //     const { data: { customer } } = response;
  //     await this.customerService.update({ data: customer });
  //     const updatedCustomer = this.customerService.find(customer.id);
  //     if (updatedCustomer) this.data = updatedCustomer;

  //     this.isAlertVisible = true;

  //     return response;
  //   } catch (error) {
  //     this.isDisplaySuccessAlert = false;
  //     this.isAlertVisible = true;
  //     throw Error('Sorry, there was an error while saving this record.');
  //   } finally {
  //     this.loading.overlay = false;
  //   }
  // }

  // protected async deny() {
  //   this.loading.overlay = true;
  //   const token = this.userService.getActiveToken();
  //   if (!token) throw Error('Unable to get token from active User');
  //   try {
  //     const response = await this.customerService.api.deny({
  //       id: this.id,
  //       authentication_token: token,
  //       customer: this.data,
  //     });

  //     // update customer data displayed
  //     const { data: { customer } } = response;
  //     await this.customerService.update({ data: customer });
  //     const updatedCustomer = this.customerService.find(customer.id);
  //     if (updatedCustomer) this.data = updatedCustomer;

  //     this.isAlertVisible = true;

  //     return response;
  //   } catch (error) {
  //     this.isDisplaySuccessAlert = false;
  //     this.isAlertVisible = true;
  //     throw Error('Sorry, there was an error while saving this record.');
  //   } finally {
  //     this.loading.overlay = false;
  //   }
  // }
}
