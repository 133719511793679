

































































import { Customer, CustomerProduct, Product } from '@/models/internal';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProductsDataTable from '@/views/Dashboard/Products/components/ProductsDataTable/ProductsDataTable.vue';
import { DataTableHeader } from 'vuetify';
import { Form } from '@/models/forms/Form';
import { Logger } from '@/tools/Logger';
import { CustomerProductService, UserService } from '@/services';

type CustomerProductsAutoApprovalLimitMap = {
  [product_id: number]: number | string;
};

@Component({
  name: 'CustomerProductsList',
  components: {
    ProductsDataTable,
  },
})
export class CustomerProductsList extends Vue {
  @Prop({ required: true })
  protected readonly customer!: Customer;

  public headers: DataTableHeader<any>[] = [
    { text: 'Thumbnail', value: 'image_url', sortable: false, filterable: false, width: 100 },
    { text: 'SKU', value: 'sku', sortable: false, filterable: false, width: 200 },
    { text: 'Title', value: 'title', sortable: false, filterable: false },
    { text: 'Inventory', value: 'inventory', width: 120, sortable: false, filterable: false },
    { text: 'Default Limit', value: 'auto_approval_limit', width: 120, sortable: false, filterable: false },
    { text: 'Custom Limit', value: 'custom_auto_approval_limit', width: 120, sortable: false, filterable: false },
  ];

  protected loading = false;

  protected page: number | string = 1;

  protected perPage: number | string = 10;

  protected totalRecords: number | string = -1;

  protected formRules = Form.rules;

  protected customerProducts: CustomerProduct[] = [];

  protected customerProductsAutoApprovalLimitMap: CustomerProductsAutoApprovalLimitMap = {};

  private readonly userService: UserService = UserService.getInstance();

  private readonly customerProductService: CustomerProductService = CustomerProductService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CustomersList' });

  protected get productsData(): Product[] {
    return this.customerProducts.map(customerProduct => new Product(customerProduct.product));
  }

  protected getCustomerProductAutoApprovalLimit(product: Product) {
    return this.getCustomerProduct(product)?.meta?.auto_approval_quantity_limit;
  }

  protected getCustomerProductAutoApprovalLimitText(product: Product) {
    const limit = this.getCustomerProductAutoApprovalLimit(product);
    return parseInt(limit) ? limit : 'N/A';
  }

  protected getCustomerProductAutoApprovalLimitClass(product: Product) {
    const limit = this.getCustomerProductAutoApprovalLimit(product);

    return parseInt(limit) ? 'font-weight-bold' : 'font-weight-bold grey--text text--lighten-2';
  }

  protected openCusomAutoApprovalLimitDialog(product: Product) {
    const currentLimit = this.customerProductsAutoApprovalLimitMap[product.id];
    this.customerProductsAutoApprovalLimitMap[product.id] = currentLimit || 0;
  }

  protected closeCustomAutoApprovalLimitDialog(product: Product) {
    const limit = this.getCustomerProductAutoApprovalLimit(product);

    if (this.customerProductsAutoApprovalLimitMap[product.id] !== limit)
      this.customerProductsAutoApprovalLimitMap[product.id] = limit || 0;
  }

  protected async setCustomAutoApprovalLimit(product: Product) {
    // Validate form input before proceeding
    if (this.formRules.numeric()(this.customerProductsAutoApprovalLimitMap[product.id]?.toString()) !== true) {
      return;
    }
    this.loading = true;
    try {
      const customerProduct = this.getCustomerProduct(product);
      if (!customerProduct) throw new Error();

      const data = await this.updateCustomerProduct(customerProduct);
      customerProduct.meta.auto_approval_quantity_limit = data.meta.auto_approval_quantity_limit;
      this.$genify.notify(`Custom limit saved for ${product.sku}`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`Error! Unable to set a custom limit for ${product.sku}`, 'error');
    } finally {
      this.loading = false;
    }
  }

  protected created() {
    this.init();
  }

  private async init() {
    this.loading = true;
    try {
      const data = await this.fetchData();
      if (data.customer_products) {
        // TODO: Refactor to use ORM (had trouble inserting nested association of Customer in parent component)
        this.customerProducts = data.customer_products.map((o: any) => new CustomerProduct(o));
        this.customerProductsAutoApprovalLimitMap = this.customerProducts.reduce((map: any, o: any) => {
          map[o.product_id] = o?.meta?.auto_approval_quantity_limit ?? null;
          return map;
        }, {});
      }
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Unable to load products', 'error');
    } finally {
      this.loading = false;
    }
  }

  private async fetchData() {
    return this.customerProductService.api.find({
      authentication_token: this.userService.getActiveToken(),
      id: this.customer.id,
      include_inventory: true,
    });
  }

  private async updateCustomerProduct(customerProduct: CustomerProduct) {
    return this.customerProductService.api.update({
      authentication_token: this.userService.getActiveToken(),
      id: this.customer.id,
      customer_product: {
        id: customerProduct.id,
        meta: {
          auto_approval_quantity_limit: parseInt(this.customerProductsAutoApprovalLimitMap[customerProduct.product_id] as string) || 0,
        },
      },
    });
  }

  private getCustomerProduct(product: Product) {
    return this.customerProducts.find(customerProduct => customerProduct.product_id === product.id);
  }
}

export default CustomerProductsList;
