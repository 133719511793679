

































import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { Customer } from '@/models/internal';
import FormBase from '@/components/FormBase/FormBase.vue';
import type { FormBaseSchema } from '@/lib/types';
import { IFormValidator } from '@/lib/interfaces';

@Component({
  name: 'CustomersForm',
  components: {
    FormBase,
  },
})
export default class CustomersForm extends Vue implements IFormValidator {
  @Ref('form')
  public readonly formRef!: any;

  @Prop({ required: true })
  protected readonly schema!: FormBaseSchema;

  @Prop({ required: false, default: () => ({ cols: 12, md: 6 }) })
  protected readonly col!: any;

  @Prop({ required: true })
  private model!: Customer;

  public valid = false;

  public validate(): boolean {
    return this.formRef.validate();
  }

  public resetValidation(): void {
    return this.formRef.resetValidation();
  }

  public reset(): void {
    this.formRef.reset();
  }

  /**
   * Validate form then emit save event when button is clicked
   */
  public save(): boolean {
    const valid = this.validate();
    if (!valid) return false;
    this.$emit('save', this.model);
    return true;
  }
}
