


















import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Loading } from '@/lib/types';
import { IEditFormView } from '@/lib/interfaces';
import { CustomerForm } from '@/models/forms';
import { Customer } from '@/models/internal';
import { CustomerService, UserService } from '@/services';
import CustomersForm from './components/CustomersForm.vue';

@Component({
  name: 'CustomersEdit',
  components: {
    CustomersForm,
  },
})
export default class CustomersEdit extends Vue implements IEditFormView<Customer> {
  @Prop()
  public id!: string;

  @Ref('customer-form')
  public readonly formRef!: any;

  public form!: CustomerForm;

  /**
   * Loading indicators for components
   */
  protected loading: Loading = {
    overlay: false,
    form: false,
  };

  /**
   * Customer Service
   */
  private readonly customerService: CustomerService = CustomerService.getInstance();

  /**
   * User Service
   */
  private readonly userService: UserService = UserService.getInstance();

  protected get schema() {
    return this.form.getSchema();
  }

  protected get model() {
    return this.form.getModel();
  }

  protected set model(model) {
    this.form.setModel(model);
  }

  public created() {
    this.init();
  }

  public init(): void {
    const customer = this.fetchData(this.id);
    if (!customer) throw Error('Unable to fetch Customer from server');
    this.form = new CustomerForm(customer);
  }

  public async save(): Promise<any> {
    this.loading.overlay = true;
    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User');
    try {
      const response = await this.customerService.api.update({
        id: this.id,
        authentication_token: token,
        customer: this.model,
      });
      return response;
    } catch (error) {
      throw Error('Sorry, there was an error while saving this record.');
    } finally {
      this.loading.overlay = false;
    }
  }

  public fetchData(id: string): any {
    return this.customerService.find(id);
  }
}
