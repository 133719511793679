









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomerProductsList from '@/views/Dashboard/Customers/components/CustomerProductsList.vue';
import { Customer } from '@/models/internal';
import { VAutoCompleteItem } from '@/lib/types/Vuetify.type';
import { CustomerService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Utility } from '@/tools/Utility';

type AutoApprovalSetting = {
  enabled: boolean;
  mode: AutoApprovalMode;
};

type AutoApprovalMode = 'all' | 'custom-all' | 'custom-sections';

@Component({
  name: 'CustomerAutoApprovalControls',
  components: {
    CustomerProductsList,
  },
})
export class CustomerAutoApprovalControls extends Vue {
  @Prop({ required: true })
  protected readonly customer!: Customer;

  protected autoApprovalSetting: AutoApprovalSetting = {
    enabled: false,
    mode: 'custom-sections',
  };

  protected autoApprovalModes: VAutoCompleteItem[] = [
    { text: this.getApprovalModeName('custom-sections'), value: 'custom-sections' },
    { text: this.getApprovalModeName('custom-all'), value: 'custom-all' },
    { text: this.getApprovalModeName('all'), value: 'all' },
  ];

  protected titleCase = Utility.titleCase;

  private readonly customerService: CustomerService = CustomerService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CustomersView' });

  protected get approvalModeName() {
    return this.getApprovalModeName(this.autoApprovalSetting.mode);
  }

  public created() {
    if (this.customer.meta.auto_approval) {
      this.autoApprovalSetting = this.customer.meta.auto_approval;
    }
  }

  protected getApprovalModeName(mode: string) {
    switch (mode) {
      case 'custom-sections':
        return 'Custom - Approve ANY section';
      case 'custom-all':
        return 'Custom - Approve ALL sections';
      case 'all':
        return 'All - 100% Approval of ALL order item quantities in ANY section';
      default:
        throw new Error(`Invalid approval mode: ${mode}`);
    }
  }

  protected async updateAutoApprovalSetting() {
    try {
      await this.customerService.api.update({
        authentication_token: this.userService.getActiveToken(),
        id: this.customer.id,
        customer: {
          meta: {
            auto_approval: this.autoApprovalSetting,
          },
        },
      });
      this.$genify.notify('Auto-Approval settings updated!', 'success');
    } catch (error) {
      this.$genify.alert('Error! Unable to update Auto-Approval settings.', 'error');
      this.logger.error(error);
    }
  }
}

export default CustomerAutoApprovalControls;
