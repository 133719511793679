


























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'StatusIndicator',
})
export default class StatusIndicator extends Vue {
  @Prop({ required: true, default: true })
  private readonly status!: boolean;

  @Prop({ required: true, default: '--' })
  private readonly label!: string;

  @Prop({ required: true, default: '--' })
  private readonly value!: string;

  /**
   * Get class-name of the status indicator based on status
   */
  private get classList() {
    return {
      'text--darken-2': true,
      'green--text': this.status,
      'red--text': !this.status,
    };
  }

  /**
   * Get the icon related to the Order's status
   */
  private get icon() {
    if (this.status) return 'mdi-check-circle';
    return 'mdi-close-circle';
  }
}
