























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Collection, Customer } from '@/models/internal';
import { CustomerService, ICustomerFindArguments } from '@/services/CustomerService';
import { DataTableHeader } from 'vuetify';
import { ITableView, PaginationOptions } from '@/lib/interfaces';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import { ApprovalStatus } from '@/lib/enum';
import { Logger } from '@/tools/Logger';
import { CollectionService, UserService } from '@/services';
import prepareData from '@/models/functions/prepareData';
import CustomersDataTable from './components/CustomersDataTable.vue';
import CustomersListControls from './components/CustomersListControls.vue';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { debounce } from 'lodash';
import { renderBatchDialog } from '@/components/Dialog/BatchProcessDialog.vue';
import FileSaver from 'file-saver';
import moment from 'moment';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'CustomersList',
  components: {
    ApprovalStatusIndicator,
    CustomersDataTable,
    CustomersListControls,
    TableRowActions,
  },
})
export default class CustomersList extends Vue implements ITableView<Customer>, IUpdateContextBar {
  public selected: Customer[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Name', value: 'name', width: 180 },
    { text: 'Phone', value: 'phone', width: 200, sortable: false },
    { text: 'Contact', value: 'contact' },
    { text: 'Address', value: 'address' },
    { text: 'City', value: 'city' },
    { text: 'On Hold', value: 'on_credit_hold', width: 100, sortable: false },
    { text: 'Auto-Approval', value: 'auto_approval_status', width: 100, sortable: false },
    { text: 'Actions', value: 'actions', width: 200, sortable: false, filterable: false },
  ];

  public data: Customer[] = [];

  public distChannels: string[] = [];

  public showOnCreditHold = false;

  /**
   * Debounced init function (used for limiting fetches from the search field)
   */
  public debouncedInit = debounce(this.init, 500);

  protected options: PaginationOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: [false],
  };

  protected loading = false;

  private readonly customerService: CustomerService = CustomerService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CustomersList' });

  private totalRecords: number | string = -1;

  private allItemsSelected = false;

  private getBatchSelection = renderBatchDialog.bind(this);

  private search = '';

  private showDeniedCustomers = false;

  private collections?: Collection[];

  protected get customers() {
    if (this.showDeniedCustomers) {
      return this.data.filter((customer) => customer.approval_status === ApprovalStatus.Denied);
    }
    return this.data.filter((customer) => customer.approval_status !== ApprovalStatus.Denied);
  }

  @Watch('selected')
  protected watchSelected() {
    this.updateContextBar();
  }

  @Watch('options', { deep: true, immediate: false })
  protected watchPagination() {
    this.loading = true;
    this.init();
  }

  @Watch('search')
  @Watch('distChannels')
  protected watchSearch() {
    this.loading = true;
    this.selected = [];
    this.options.page = 1;
    this.debouncedInit();
  }

  @Watch('showOnCreditHold')
  protected watchShowOnCreditHold() {
    this.loading = true;
    this.selected = [];
    this.options.page = 1;
    this.init();
  }

  public created() {
    this.init();
    this.updateContextBar();
  }

  /**
   * Update the ContextBar with view-related actions
   */
  public updateContextBar() {
    const actions = [
      {
        color: 'primary',
        icon: 'mdi-cloud-refresh',
        label: 'Sync Sites',
        callback: this.onClickSyncCustomerData,
      }, {
        label: 'Download CSV',
        icon: 'mdi-file-delimited-outline',
        color: 'primary',
        callback: this.downloadCsv,
      },
    ];

    if (this.selected.length > 0) {
      actions.push(
        {
          label: 'Enable for Collections',
          callback: () => this.enableCustomersForCollections(this.selected),
          icon: 'mdi-plus-box-multiple',
          color: 'success',
        }, {
          label: 'Disable for Collections',
          callback: () => this.disableCustomersForCollections(this.selected),
          icon: 'mdi-minus-box-multiple',
          color: 'error',
        },
      );
    }

    ContextBarManager.setActions(...actions);
  }

  public async fetchData() {
    const sortBy = this.options.sortBy.reduce((acc, curr, idx, arr) => {
      const sortDirection = (!this.options.sortDesc[idx]) ? 'asc' : 'desc';
      const sortString = acc + `${curr}:${sortDirection}`;
      return (idx < arr.length - 1) ? sortString + ',' : sortString;
    }, '') || undefined;

    const params: ICustomerFindArguments = {
      authentication_token: this.userService.getActiveToken(),
      page: this.options.page,
      per_page: this.options.itemsPerPage,
    };
    if (this.search) {
      params.name_containing = this.search;
    }
    if (this.distChannels.length > 0) {
      params.dist_channels = this.distChannels;
    }
    // If the pagination has the `All` flag, delete pagination params
    if (this.options.itemsPerPage === -1) {
      params.page = 1;
      params.per_page = this.totalRecords;
    }

    if (sortBy) {
      params.sort = sortBy;
    }

    // Show on credit hold
    if (this.showOnCreditHold) {
      params.show_on_credit_hold = this.showOnCreditHold;
    }

    return this.customerService.api.find(params);
  }

  public async init() {
    this.loading = true;
    try {
      let customers;
      let total;

      const response = await this.fetchData();

      if (typeof response === 'object' && Array.isArray(response)) {
        customers = response;
      } else if (typeof response === 'object') {
        customers = response.customers;
        total = response.total;
      }

      if (typeof total !== 'undefined') {
        this.totalRecords = total;
      }

      const preparedData = prepareData(customers, CustomerService.mapData);
      await this.customerService.insert({ data: preparedData });
      this.data = this.customerService.findIn(customers.map((data: any) => data.Customer?.id || data.id));
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Navigate to a summary of this Customer's data
   * @param customer
   */
  protected async viewCustomer(customer: Customer) {
    return this.$router.push({
      name: 'customers-view',
      params: {
        id: String(customer.id),
      },
    });
  }

  protected async fetchCollections() {
    const { collections } = await this.collectionService.api.find({
      authentication_token: this.userService.getActiveToken(),
      show_blacklist_collections: true,
    });

    return collections;
  }

  protected async disableCustomersForCollections(customers: Customer[]) {
    if (!this.collections) {
      this.collections = await this.fetchCollections();
    }

    const collection = this.data;
    if (!collection) throw Error('Unable to enable Sites for this Collection');
    const { sources, targets } = await this.getBatchSelection<Customer, Collection>({
      title: 'Remove Sites from Collections',
      subtitle: 'Select one or more Collections to remove your selected Sites from',
      sources: customers,
      targets: this.collections,
      sourceType: 'Customers',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });
    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchDisableCustomers(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            name_containing: this.search,
            dist_channels: this.distChannels,
          },
        );
      } else {
        result = await this.collectionService
          .batchDisableCustomers(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  protected async enableCustomersForCollections(customers: Customer[]) {
    if (!this.collections) {
      this.collections = await this.fetchCollections();
    }

    const collection = this.data;
    if (!collection) throw Error('Unable to enable Sites for this Collection');
    const { sources, targets } = await this.getBatchSelection<Customer, Collection>({
      sources: customers,
      targets: this.collections,
      title: 'Enable Sites for Collections',
      subtitle: 'Select one or more Collections to enable for your selected Sites',
      sourceType: 'Customers',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });
    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchEnableCustomers(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            name_containing: this.search,
            dist_channels: this.distChannels,
          },
        );
      } else {
        result = await this.collectionService.batchEnableCustomers(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  /**
   * Sync latest Customer data
   */
  protected async onClickSyncCustomerData() {
    this.loading = true;
    this.selected = [];
    this.options.page = 1;
    try {
      const synced = await this.customerService.api.sync({
        authentication_token: this.userService.getActiveToken(),
      });
      if (synced) this.$genify.notify('Site data synced successfully!', 'success');
      this.init();
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to sync Site data.', 'error');
    }
    this.loading = false;
  }

  /**
   * Edit a Customer record from the data table
   * @param customer
   */
  // async editCustomer(customer: Customer) {
  //   return this.$router.push({
  //     name: 'customers-edit',
  //     params: {
  //       id: String(customer.id),
  //     },
  //   });
  // }

  /**
   * Approve a Customer record's status from the data table
   * @param customer
   */
  // async approveCustomer(customer: Customer) {
  //   this.loading.overlay = true;
  //   const token = this.userService.getActiveToken();
  //   if (!token) throw Error('Unable to get token from active User');
  //   try {
  //     const response = await this.customerService.api.approve({
  //       id: customer.id,
  //       authentication_token: token,
  //       customer,
  //     });

  //     await this.customerService.update({ data: response.data.customer });
  //     const updatedCustomer = this.customerService.find(response.data.customer.id);
  //     if (updatedCustomer) {
  //       const i = this.data.findIndex((customer) => customer.id === updatedCustomer.id);
  //       this.data[i] = updatedCustomer;
  //     }

  //     return response;
  //   } catch (error) {
  //     throw Error('Sorry, there was an error while saving this record.');
  //   } finally {
  //     this.loading.overlay = false;
  //   }
  // }

  /**
   * Deny a Customer record's status from the data table
   * @param customer
   */
  // async denyCustomer(customer: Customer) {
  //   this.loading.overlay = true;
  //   const token = this.userService.getActiveToken();
  //   if (!token) throw Error('Unable to get token from active User');
  //   try {
  //     const response = await this.customerService.api.deny({
  //       id: customer.id,
  //       authentication_token: token,
  //       customer,
  //     });

  //     await this.customerService.update({ data: response.data.customer });
  //     const updatedCustomer = this.customerService.find(response.data.customer.id);
  //     if (updatedCustomer) {
  //       const i = this.data.findIndex((customer) => customer.id === updatedCustomer.id);
  //       this.data[i] = updatedCustomer;
  //     }

  //     return response;
  //   } catch (error) {
  //     throw Error('Sorry, there was an error while saving this record.');
  //   } finally {
  //     this.loading.overlay = false;
  //   }
  // }

  /**
   * Select all the visible Customer rows where the status is
   * pending
   */
  protected selectPendingStatus() {
    this.selected = this.data.filter((item) => item.approval_status === ApprovalStatus.Pending);
  }

  protected canRead() {
    return this.$ability.can('read', Customer);
  }

  // canUpdate(customer: Customer) {
  //   return this.$ability.can('update', customer);
  // }

  // canUpdateStatus(customer: Customer) {
  //   return this.$ability.can('approve', customer);
  // }

  protected async updateOnCreditHold(onCreditHold: boolean, customer: Customer) {
    this.loading = true;
    try {
      const authenticationToken = this.userService.getActiveToken();
      const response = await this.customerService.api.updateOnCreditHold({
        authentication_token: authenticationToken,
        id: customer.id,
        on_credit_hold: onCreditHold,
      });
      if (response) {
        if (onCreditHold) {
          this.$genify.notify(`Enabled credit hold for ${customer.name}.`, 'success');
        } else {
          this.$genify.notify(`Disabled credit hold for ${customer.name}.`, 'success');
        }
      }
      this.init();
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to update Site data.', 'error');
    }
  }

  private async downloadCsv() {
    try {
      const authenticationToken = this.userService.getActiveToken();
      const response = await this.customerService.api.download({
        authentication_token: authenticationToken,
      });
      FileSaver.saveAs(response, `vch_sites_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`);
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to download Site data.', 'error');
    }
  }
}
